<template>
    <div class="date__input" v-bind:class="{
        error : field.isError,
        focus : isFocused,
        disabled: disabled
    }"> 
        <input type="text" inputmode="numeric"
            :disabled="disabled"
            :maxlength="dateFormat.format.length" 
            placeholder="Date" 
            v-model="value"
            @focus="isFocused = true" 
            @blur="isFocused = false"
            v-click-outside="validate"/>
        <div class="calendar" @click="openDataPicker" v-bind:class="{
            disabled: disabled,
        }">
            <input type="text" style="display: none;" ref="input">
            <span class="iconify" 
                data-icon="bx:calendar" 
                style="" 
                data-width="24" 
                data-height="24">
            </span>
        </div>
    </div>
</template>

<script>
import AirDatepicker from 'air-datepicker'
import 'air-datepicker/air-datepicker.css';
import { getStringByFormat } from '../../scripts/date';
import Field from '../../scripts/field';
import Form from '../../scripts/form';

export default {
    name: 'DateInput',
    props: {
        dateFormat: Object,
        date: Date,
        dateString: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
        },
        form: {
            type: Form,
        }
    },
    model: {
        prop: 'dateString',
        event: 'update:dateString'
    },
    directives: {
      'click-outside': {
          bind: function (el, binding, vnode) {
            el.clickOutsideEvent = function (event) {
              // here I check that click was outside the el and his children
              if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
              }
            };
            document.body.addEventListener('click', el.clickOutsideEvent)
          },
          unbind: function (el) {
            document.body.removeEventListener('click', el.clickOutsideEvent)
          },
        }
    },
    created(){
        if (this.form) {
            this.form.addField(this.field)
        }
        this.field.validator = this.validate
    },
    computed: {
        value: {
            get() {
                return this.$props.dateString
            },
            set(newValue) {

                this.field.isError = false

                let fixedDateString = this.dateHelper(newValue)
                this.$emit('update:dateString', fixedDateString)

                if(fixedDateString.length != 10) 
                    return

                this.validate(newValue)
            },
        },
    },
    data() {
        return {
            isFocused: false,
            dataPicker: {},
            field: new Field(this.name ?? "")
        };
    },

    methods: {
        dateHelper(newValue){
            let date = newValue
            let lastChar = newValue[newValue.length - 1]
            let separator = this.dateFormat.sepr
            let oldFieldValue = this.value

            let isYearFirst = (this.dateFormat.places["YYYY"] ?? this.dateFormat.places["YY"]) == 0
            if (isYearFirst){
                return newValue
            }

            switch(newValue.length){
                case 1:
                    if ([4,5,6,7,8,9].includes(Number(lastChar))){
                        date = "0" + lastChar + separator
                    }
                    break;
                case 2:
                    if (lastChar == separator){
                        date = "0" + oldFieldValue + separator
                        break
                    }
                    date += separator
                    break

                case 4:
                    if ([2,3,4,5,6,7,8,9].includes(Number(lastChar))){
                        date = oldFieldValue + "0" + lastChar + separator
                    }
                    break;
                case 5:
                    if (lastChar == separator){
                        date += "0" + oldFieldValue + separator
                        break
                    }
                    date += separator
                    break
            }

            return date
        },
        repairToFormat(dateString){
            let sepr = this.dateFormat.sepr
            let parts = dateString.split(sepr)

            if (parts.length < 3){
                return dateString
            }


            let resultDateString = []
            Object.keys(this.dateFormat.places).forEach(dateFormatPart => {
                let index = this.dateFormat.places[dateFormatPart]
                
                let partLength = dateFormatPart.length
                let isDay = dateFormatPart == "dd" || dateFormatPart == "d"
                let isMon = dateFormatPart == "MM" || dateFormatPart == "M"

                let isYear = !isDay && !isMon

                if (!isYear && parts[index] && partLength == 2 && parts[index].length == 1){
                    parts[index] = "0" + parts[index] 
                }

                if (isYear && parts[index] && partLength == 4 && parts[index].length == 2){
                    parts[index] = "20" + parts[index] 
                }

                if (isYear && parts[index] && partLength == 2 && parts[index].length == 1){
                    parts[index] = "0" + parts[index] 
                }

                resultDateString[index] = parts[index]
            }) 
            return resultDateString.join(sepr)
        },
        validate(value){
            if (this.disabled)
                return
            if (typeof value == "undefined" || typeof value == "object")
                value = this.dateString
            if (value && value.length > 0){
                value = this.repairToFormat(value)
                this.field.isError = !this.validateDateString(value)
            }
        },
        openDataPicker(){
            if (this.disabled) return
            
            this.dataPicker = new AirDatepicker(this.$refs['input'], {
                inline: false,
                isMobile: true,
                container: 'datapicker',
                autoClose: true,
                onSelect: this.changeDate,
                selectedDates: [this.date],
                onRenderCell({date, cellType}) {
                // Disable all 12th dates in month
                    if (cellType === 'day') {
                        let now = new Date()
                        now.setHours(0, 0, 0, 0)
                        if ( date.getTime() < now.getTime()) {
                            return {
                                disabled: true,
                                classes: 'disabled-class'
                            }
                        }
                    }
                }
            });
            this.dataPicker.show()
        },
        changeDate({date}){
            console.log('date :>> ', date);
            this.value = getStringByFormat(this.dateFormat.format, date)
            this.validate()
        },

        validateDateString(value){

            const DDMMYYYY = /^[0-9]{2}[\.\/\-]{1}[0-9]{2}[\.\/\-]{1}[0-9]{4}$/g
            const YYYYMMDD = /^\d{4}[\-\/\s]?((((0[13578])|(1[02]))[\-\/\s]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[\-\/\s]?(([0-2][0-9])|(30)))|(02[\-\/\s]?[0-2][0-9]))$/

            let isYearFirst = (this.dateFormat.places["YYYY"] ?? this.dateFormat.places["YY"]) == 0

            return isYearFirst? YYYYMMDD.test(value) : DDMMYYYY.test(value)
        }

    },
};
</script>

<style scoped>
.date__input{
    width: 144px;
    height: 40px;
    display: grid;
    grid-template-columns: calc(100% - 35px) 35px;
    
    border: 1px solid;
    background-color: white;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
}


.date__input.disabled{
    pointer-events: none;
    background: #e2e2e2;
    color: grey;
}

.date__input.focus{
    border-color: hwb(212 0% 0%);
    box-shadow: 0px 0 0 2px #cbe3ff;
}

.date__input.error{
    border-color: #f14668;
    box-shadow: 0px 0 0 2px rgba(241, 70, 104, 0.25);
}

input {
    font-size: 16px;
    padding: 7px 11px;
    height: 38px;
    border: none;
    border-radius: 4px;
}

input[type="text"]:disabled {
    background: #e2e2e2;
    color: grey;
}

input:focus{
    outline: none;
    box-shadow: none;
}


.error > input {
    color: 	#f14668;
}

.air-datepicker{
    display: none;
}

.calendar{
    color: #0075ff;
    display: flex;
    align-items:center;
}

.calendar.disabled{
    color: grey;
}
</style>