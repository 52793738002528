function hexToRgb(hex){
	return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
		,(m, r, g, b) => '#' + r + r + g + g + b + b)
		.substring(1).match(/.{2}/g)
		.map(x => parseInt(x, 16))
}

function rgbToArray(rgbString) {
	var rgbaArray = rgbString.replace(/[^\d,]/g, '').split(',');
	return rgbaArray.map(function(value) {
		return parseInt(value);
	});
}

function isDarkColor(color) {
	return red*0.299 + green*0.587 + blue*0.114 > 186
}

function isLightColor(color) {
	return red*0.299 + green*0.587 + blue*0.114 <= 186	
}

function addOpacityToColor(color, decimalOpacity){
	if (!color) return 
	
	let rgba = []
	if (color.includes("rgb")){
		rgba = rgbToArray(color)
	} else if (color.includes("#")) {
		rgba = hexToRgb(color)
	}

	return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${decimalOpacity})`
}

export {hexToRgb, rgbToArray, addOpacityToColor, isDarkColor, isLightColor}