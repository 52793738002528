<template>
    <div class="slot_color_picker">
        <div class="slot" @click="() => show = true" v-bind:style="{'background':color.mainColor,'color': color.textColor}">
            Text
        </div>
        <vue-final-modal
            v-show="show"
            v-model="show"
            @click-outside="() => show = false">
            <div class="modal__window">
                <div class="color__picker">
                    <div class="slot" v-bind:style="{'background':newColors.mainColor,'color': newColors.textColor}">
                        {{slotTitle || "Slot title"}}
                    </div>
                    <color-panel v-model="newColors.mainColor" @change="updateTextColor"></color-panel>
                    <div class="submit_bottons_cont" v-bind:style="{'padding': 0}">
                        <b-button @click="setColor" type="is-info">Choose</b-button>
                        <b-button @click="show = false" type="is-info is-light">Cancel</b-button>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
import { hexToRgb } from '../../scripts/color.js'
import { Button } from 'buefy'

export default {
    name: 'SlotColorPicker',
    components: {
        Button
    },
    model:{
        prop:'color',
        event: 'update:color'
    },  
    props: {
        color: {
            type: Object,
            required: true,
        },
        slotTitle: {
            type: String,
            default: "Slot text"
        },
    },
    watch: {
        color(newValue){
            this.newColors = newValue
        }
    },
    data() {
        return {
            show: false,
            newColors: {
                mainColor: this.color.mainColor,
                textColor: this.color.textColor,
            }
        };
    },
    methods: {
        updateTextColor() {
            let rgbColor = hexToRgb(this.newColors.mainColor)

            let red = rgbColor[0]
            let green = rgbColor[1]
            let blue = rgbColor[2]

            if (1 - (0.299 * red + 0.587 * green + 0.114 * blue) / 255 < 0.5){
                this.newColors.textColor = "#000000"
            } else {
                this.newColors.textColor = "#ffffff"
            }
        },
        setColor(){
            this.$emit('update:color', {
                mainColor: this.newColors.mainColor,
                textColor: this.newColors.textColor,
            })
            this.show = false
        },
    },
};
</script>

<style scoped>
.slot_color_picker{
    width: 106px;
}
.slot{
    height: 36px;
    border: 1px solid #a7a7a7;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
}

.modal__window{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.color__picker{
    background: white;
    padding: 20px;
    border-radius: 8px;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 16px;
}

.submit_bottons_cont{
    /* position: absolute;
    bottom: 0;
    width: 100%; */
    padding: 20px;
    padding-top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    background: white;
    height: 40px;
}

.save__button{
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    height: 40px;
    background: #0075ff;
    border-radius: 7px;
    color: white;

    font-size: 20px;
    font-weight: bold;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

.cancel__button{
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    height: 40px;
    color: #0075ff;
    text-decoration: underline;
}
</style>