<template>
    <div class="card_short_settings">
        <div class="calendar__name">
            {{calendarName}}
        </div>
        
        <div class="settings__cont">
            <div class="settings__fields">
            <!-- Template -->
            <div class="form__template" v-if="templates">
                <SlotTemplateSelector
                    v-model="form.template"
                    :templates="templates"
                />
            </div>
            <!-- Title -->

            <b-input ref="title" v-model="form.title" placeholder="Title" expanded></b-input>


            <!-- Date, time, duration -->

            <div class="slot__property date_duration_field">
                <div class="multi__field">
                    <DateInput v-model="form.dateString" :dateFormat="dateFormat" :date="form.date" :form="form" name="date"/>
                    <TimeInput v-model="form.timeString" :form="form" name="time"/>
                </div>
                <div class="duration">
                    <div>Duration</div>
                    <BaseInput
                    inputType="number"
                    v-model="form.duration" placeholder="60"
                    v-bind:style="{width:'45px'}"
                    :form="form" name="duration"
                    :validator="digitsOnly"
                    :showErrorMessage="false"/>
                </div>
                <div class="timezone" v-if="form.timezone">
                    <span @click="() => {showTimezoneSelect = true}">
                        {{form.timezone.name}}
                    </span>
                </div>
                <div class="timezone-difference">
                    <div class="current__datetime" v-if="calendarTimezone && calendarTimezone.id != -1 && calendarTimezone.id != form.timezone.id">
                        {{calendarTZdateTime }}
                    </div>
                    <div class="current_week_day" v-else>
                        {{ dateOfWeek }}
                    </div>
                </div>
            </div>

            <!-- Description -->

            <textarea class="textarea" placeholder="Description"
                v-model="form.description">
            </textarea>

            <div class="slot__property busy_and_color">

                <!-- Is busy -->
                <b-checkbox v-model="isBusy">Mark as busy</b-checkbox>

                <!-- Slot color -->
                <SlotColorPicker v-model="cell" :slotTitle="form.title"/>
            </div>


            <!-- Slot guests -->
            <div class="slot__property add_guest">
                <div class="search__cont">
                    <div class="icon">
                        <span class="iconify" data-icon="uil:users-alt" data-width="24" data-height="24"></span>
                    </div>
                    <div class="search__field" @click="showSearchGuestList">
                        <b-input v-model="searchGuest"  placeholder="Add guest"></b-input>
                    </div>
                </div>
                <div class="guests__cont">
                    <div class="guest" v-for="guest, index in searchGuestList" :key="`guest__${index}`">
                        <div class="guest_avatar_cont">
                            <div class="guest__avatar"></div>
                        </div>
                        <div class="guest__name">
                            {{guest.name}}
                        </div>
                        <div class="guest_del_button" @click="() => deleteGuest(guest.id)">
                            <span class="iconify" data-icon="uil:multiply" data-width="20" data-height="20"></span>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="form__footer">
            <div class="template__tools">
                <b-checkbox v-model="form.isCreateTemplate">Create template</b-checkbox>
            </div>
            <div class="submit_buttons_cont">
                <b-button type="is-info" @click="submitHandler(form)">
                    Save
                </b-button>
                <b-button type="is-info is-light" @click="closeHandler(form.date)">
                    Cancel
                </b-button>
            </div>
        </div>
        <TimezoneSelector
            v-model="form.timezone"
            v-show="showTimezoneSelect"
            :show="showTimezoneSelect"
            :closer="() => {showTimezoneSelect = false}"
        />
    </div>
</template>

<script>
import Slot from '../../../scripts/slot';
import TimeInput from '../../inputs/TimeInput.vue';
import DateInput from '../../inputs/DateInput.vue';
import SlotColorPicker from '../../inputs/SlotColorPicker.vue';
import TimezoneSelector from '../../inputs/timezone-selector/TimezoneSelector.vue';
import { convertTZ, getStringByFormat, isEqualDate, UTCString } from '../../../scripts/date';
import CMS from '../../../service/cms/service';
import { bus } from '../EventBus';
import SlotSettingsFormInstance from '../../forms/slot-settings/formInstance';
import BaseInput from '../../inputs/BaseInput.vue';
import TextareaInput from '../../inputs/TextareaInput.vue';
import SlotTemplateSelector from '../../inputs/slot-template-picker/SlotTemplateSelector.vue';
import { ADMIN_MEMBERSHIP_LEVEL, MANAGER_MEMBERSHIP_LEVEL, OWNER_MEMBERSHIP_LEVEL } from '../../../scripts/membershipLevels';

const WEEK_DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
]

export default {
    name: 'SlotShortSettings',
    components: {
        TimeInput,
        BaseInput,
        DateInput,
        TextareaInput,
        SlotColorPicker,
        TimezoneSelector,
        SlotTemplateSelector,
    },
    props: {
        eventSlot: Slot || Object,
        submitHandler: {
            type: Function,
            required: true,
        },
        closeHandler: {
            type: Function,
            required: true,
        },
    },
    watch: {
        'form.date' : {
            handler(newValue){
                this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)
            }, 
            deep: true,
        },
        'form.timezone' : {
            handler(newValue){
                if (!newValue) return
                this.form.timezone = newValue
                this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)   
            },
            deep: true,
        },
        'form.template' : {
            handler(template){
                this.form.updateByTemplate(template)
            },  
            deep: true,
        },
        'form.duration' : {
            handler(duration){
                if (typeof duration == 'string'){
                    this.form.fields['duration'].setError("duration is invalid")
                }

            },  
            deep: true,
        },
        'form.timeString' : {
            handler(newValue) {
                let timeField = this.form.fields["time"]
                if (newValue.length != 5 || timeField.isError) 
                    return
                
                let browserTimezone = this.$store.getters.browserTimezone
                this.form.updateTime(newValue, browserTimezone)
            },
            deep: true,
        },
        'form.dateString' : {
            handler(newValue, oldValue) {

                if (newValue.length != 10) return

                let browserTimezone = this.$store.getters.browserTimezone
                if (oldValue) {
                    let workStart = this.$store.getters.calendarWorkStart
                    let workEnd = this.$store.getters.calendarWorkEnd 
                    this.form.updateDate(newValue, browserTimezone, {
                        start: workStart,
                        end: workEnd,
                    })
                }
                this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)
            },
            deep: true,
        },
    },
    computed: {
        calendarUid(){
			if (this.$route.name != "calendar" || !this.userHaveAccess )
				return this.$store.getters.getDefaultCalendar
			return this.$route.params.calendarUid
		},
        calendarName(){
            let calendarUid = this.$store.getters.calendarUid
            if (calendarUid == "" || !this.userHaveAccess) {
                let calendarList = this.$store.getters.userManagedCalendarList
                let defaultCalendarUid = this.$store.getters.getDefaultCalendar
                let defaultCalendar = calendarList[defaultCalendarUid]
                return (defaultCalendar.title)
            }
            return this.$store.getters.calendarTitle
        },
		userHaveAccess(){
            let defaultCalendarUid = this.$store.getters.getDefaultCalendar
			if (this.$route.name == "calendar" && 
				this.$route.params.calendarUid == defaultCalendarUid)
				return true;
			let level = this.$store.getters.getMembershipLevel
            if (level == -1)
				return false
			return level == MANAGER_MEMBERSHIP_LEVEL ||
				level == OWNER_MEMBERSHIP_LEVEL ||
				level == ADMIN_MEMBERSHIP_LEVEL
		},
        templates(){
			return this.$store.getters.calendarSlotTemplates
		},

        dateFormat(){
            return this.$store.getters.dateFormat
        },
        dateOfWeek(){
            return WEEK_DAYS[this.form.date.getDay()]
        },
        calendarTimezone(){
            let calendarTimezone 
            if (this.userHaveAccess){
                calendarTimezone = this.$store.getters.getTimezone
            } else {
                let userTimezoneId = this.$store.getters.userTimezoneId
                calendarTimezone = this.$store.getters.getTimezoneByID(userTimezoneId) 
            }
            if (!this.form)
                return calendarTimezone
            this.form.calendarTimezone = calendarTimezone
            if (calendarTimezone.id != -1 && !this.form.timezone){

                let slotTZid = this.form.timezoneId ?? calendarTimezone.id
                this.form.timezone = this.$store.getters.getTimezoneByID(slotTZid)

                if (calendarTimezone.id != this.form.timezone.id){
                    this.form.date = convertTZ(this.form.date, calendarTimezone.name, this.form.timezone.name)
                    this.calendarTZdateTime = this.calendarDateTimeInTZ(this.form.date)
                } 

                this.form.dateFormat = this.dateFormat
                this.form.dateString = getStringByFormat(this.dateFormat.format, this.form.date)
            }
            return calendarTimezone
        },

        cell:{
			get(){
				return {
					mainColor: this.form.cellBackgroundColor,
					textColor: this.form.cellTextColor,
				}
			},
			set(newValue){
				this.form.cellBackgroundColor = newValue.mainColor
				this.form.cellTextColor = newValue.textColor
			}
		},
    },
    data() {
        return {
            
            form: null,

            showTimezoneSelect: false,
            calendarTZdateTime: null,
            isBusy: false,

            searchGuest: "",
            searchGuestList: [],
        };
    },

    created() {
        if (this.eventSlot){
            this.form = new SlotSettingsFormInstance({
                ...this.eventSlot.settings,
                startAt: this.eventSlot.period.start.date,
                duration: this.eventSlot.period.duration,
                fixedAttenders: this.eventSlot.attenders,
            }, true)
        } else {
            let date = null
            let tableHeader = this.$store.getters.tableHeader
            if (tableHeader)
                date = tableHeader.selectedDate
            this.form = new SlotSettingsFormInstance({
                startAt: date,
                isCreate: true,
                timezoneId : this.calendarTimezone.id
            }, true)
            this.form.timeString = ""   
        }

        console.log('this.userHaveAccess :>> ', this.userHaveAccess);        
        if (this.$route.name != 'calendar' || !this.userHaveAccess)
            this.$store.dispatch('setup-slot-templates')
    },

    mounted() {
        if (this.form.isCreate) {
            setTimeout(() => this.$refs["title"].focus(), 500)
        } else if (!this.form.isCreate && this.form.title == "") {
            this.$refs["title"].focus()
        }
    },

    methods: {
        calendarDateTimeInTZ(initDate){
            if (!this.form)
                return
            let date = convertTZ(initDate, this.form.timezone.name, this.calendarTimezone.name)
            let calendarTZName = this.calendarTimezone.name.split(' ')[0]
            let result = date.toTimeString().slice(0,5) + ` ${calendarTZName}`

            if (date.getDate() != initDate.getDate()){
                result = date.toLocaleDateString() + " " + result
            }

            this.form.calendarDate = date

            return result
        },

        deleteGuest(guestId){
            let guestIndex = this.searchGuestList.findIndex(guest => guest.id == guestId)
            console.log('guestIndex', guestIndex)
            if (guestIndex == -1) return

            this.$delete(this.searchGuestList, guestIndex);
        },
        showSearchGuestList(){
            this.searchGuestList = [
                {name: "Timur", id:2},
                {name: "Timur", id:3},
            ]
        },
        digitsOnly(value){
            let fixed = value.split('').filter(char => {
                return char != '.' && char != ',' && char != '-'
            }).join('')

            return fixed
        }
    },
};
</script>

<style scoped>
.textarea:not([rows]){
    min-height: 5em;
    max-height: 9em;
}

.form__template{
    display: flex;
    justify-content: flex-end;
} 

.card_short_settings{
    width: 100%;
    display: grid;
    grid-template-rows: 40px calc(100% - 40px);
}

input[type='checkbox']{
    height: 18px;
    width: 18px;
}

.slot__property{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.busy_and_color{
    justify-content: space-between;
}
.add_guest{
    flex-direction: column;
}

.settings__fields{
    padding: 10px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.is__busy{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.multi__field{
    display: flex;
    flex-direction: row;
    gap:10px;
    width: 218px;
}

.timezone{
    color: rgb(0, 117, 255);
    text-decoration: underline;
    font-size: 11px;
    cursor: pointer;
}

.current__datetime{
    text-align: right;
    font-size: 11px;
}

.current_week_day{
    text-align: right;
    font-size: 11px;
}

.date_duration_field{
    display: grid;
    grid-template-columns: calc(100% - 16px - 110px) 110px;
    grid-column-gap: 16px;
    grid-row-gap: 7px;
}

.search__cont{
    display: flex;
    align-items: center;
    gap: 16px;
}

.search__cont > .search__field {
    width: 100%;
}

.calendar__name{
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    font-weight: 600;
    border-bottom: 2px solid rgb(240, 240, 240);
}

.settings__cont{
    padding-bottom: 0;
    min-width: 260px;
    height: 100%;
}
.submit_buttons_cont{
    padding: 20px;
    padding-top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    background: white;
    height: 40px;
}

.guests__cont{
    margin-left: 40px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.guest{
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 40px;
}

.guest > *{
    display: flex;
    align-items: center;
}

.guest__avatar{
    border:1px solid black;
    border-radius: 20px;
    width: 32px;
    height: 32px;
}
.guest__name{
    width: calc(100% - 32px * 2);
}

/* .guest_del_button{

} */

.duration{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    font-size: 14px;
}

.form__footer{
    position: fixed;
    bottom: 0;
    right: 0;

    display: flex;
    gap: 16px;
    flex-direction: column;

    width: 100%;
    background: white;
    border-top: 1px solid lightgrey;
    padding-bottom: 16px;
    z-index: 5;

}

.template__tools{
    padding: 10px 24px;
    height: 40px;
}

.choose_template_cont{
    display: flex;
    justify-content: flex-end;
}

.checkbox__cont{
    display: grid;
    grid-template-columns: 18px calc(100% - 10px - 18px) ;
    grid-column-gap: 10px;
}

.checkbox__cont > *{
    display: flex;
    align-items: center;
}

</style>