<template>
    <div class="slot_template_selector">
        <div class="label__cont" v-if="templates.length">
            <div class="label" @click="openModal">
                <a v-if="currentTemplate && currentTemplate.title">{{currentTemplate.title}}</a>
                <a v-else>Choose template</a>
            </div>
        </div>
        <vue-final-modal v-model="show" @click-outside="closeModal">
            <div class="main__cont" v-if="show">
                <div class="header__cont">
                    <div class="close__button" @click="closeModal">
                        <span class="iconify"  data-width="24" data-height="24" data-icon="uil:arrow-left"></span>
                    </div>
                    <div class="header__title">Choose template</div>
                </div>

                 <!-- <div class="search__field">
                    <b-input type="text" placeholder="Search template" v-model="searchString" @input="evt=>searchString=evt.target.value">
                    </b-input>
                    <div class="clear__button" v-if="searchString.length > 0" @click="() => searchString = ''">
                        <span class="iconify" data-icon="uil:multiply" data-width="20" data-height="20"></span>
                    </div>
                </div> -->

                <b-input placeholder="Search"
                    v-model="searchString"
                    icon="magnify"
                    :icon-right="searchString.length > 0 ? 'close-circle' : ''"
                    icon-right-clickable
                    @icon-right-click="searchString = ''">
                </b-input>

                <div class="template__list">
                    <div v-if="filterSearch(searchString).length > 0">
                        <b-button type="is-danger is-light" @click="selectTemplate(null)" expanded outlined>
                            Without template
                        </b-button>
                        <b-collapse
                        class="card"
                        animation="slide"
                        v-for="(template, index) of filterSearch(searchString)"
                        :key="index"
                        :open="isOpen == index"
                        @open="isOpen = index"
                        :aria-id="'contentIdForA11y5-' + index">
                        <template #trigger="props">
                            <div class="card-header"
                                role="button"
                                :aria-controls="'contentIdForA11y5-' + index"
                                :aria-expanded="props.open"
                            >
                                <div class="card-header-title">
                                    <b-tag v-bind:style="{
                                        background: template.cellBackgroundColor,
                                        height: '18px',
                                    }"></b-tag>
                                    {{ template.title || "Template" }}
                                </div>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>
                        <div class="card-content" style="padding: 0">
                            <div class="content">
                                <TemplateDetail :template="template"/>
                            </div>
                        </div>
                        <footer class="card-footer" >
                            <a class="card-footer-item" @click="selectTemplate(template)" v-bind:style="{
                                color: template.cellBackgroundColor
                            }">Choose</a>
                            <a class="card-footer-item" v-bind:style="{
                            color: template.cellBackgroundColor
                        }">Edit</a>
                        </footer>
                    </b-collapse>
                    </div>
                  
                    <div class="not_found_message" v-else >
                        Templates not found
                    </div> 
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
export default {
    name: 'SlotTemplateSelector',
    model:{
        prop:'currentTemplate',
    },
    components:{
        TemplateDetail: () => import("./TemplateDetail.vue")
    },
    props: {
        currentTemplate: {
            type: Object
        },
        templates: {
            type: Array,
        },
    },
    data() {
        return {
            show: false,
            isOpen: null,
            searchString: "",
        };
    },
    methods: {
        openModal(){
            this.show = true
        },
        closeModal(){
            this.show = false
        },
        selectTemplate(template){
            this.$emit('input', template)
            this.closeModal()
        },
        filterSearch(searchString){
            if (searchString == "")
                return this.templates
            let lowerSearchString = searchString.toLowerCase();
            let allTemplates = this.templates
            if (!allTemplates){
                return []
            }
            let templateTitle
            return allTemplates.slice(1).filter((template) => {
                templateTitle = template.title
                if (!templateTitle) {
                    templateTitle = ""
                }
                return templateTitle.toLowerCase().includes(lowerSearchString)
            })
        }
    },
};
</script>

<style scoped>

.card-header-title{
    display: flex;
    flex-direction: row;
    gap: 12px;
    font-size: 14px;
}

.label{
    color: rgb(0, 117, 255);
    text-decoration: underline;
    font-size: 12px;
}

.header__title{
    height: 32px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}
.main__cont{
    margin: 42px auto;
    width: 320px;
    max-height: calc(100vh - 84px);
    background: white;
    border-radius: 7px;
    padding: 16px;

    display: grid;
    grid-template-rows: 32px 32px calc(100vh - 32px - 64px);
    grid-row-gap: 16px;
}

.template__list{
    padding: 8px;
    height: calc(100% - 96px - 25px);
    overflow-y: auto;
}

input {
    width: inherit;
    padding: 6px 6px 7px 6px;
    height: 32px;
    font-size: 16px;
    border-radius: 2px;
    border: none;
}

.items__cont  {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    padding: 10px;
    padding-top: 10px;
   
}

.search__field {
    height: 34px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
    margin: 0 10px;
    display: grid;
    grid-template-columns: calc(100% - 16px - 37px) 37px;
    grid-column-gap: 16px;
}

.card__content{
    padding: 0;
}

.close__button{
    position: absolute;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clear__button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.not_found_message{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
}

.search__field > input {
    border: none;
}

input:focus{
    outline: none;
    box-shadow: none;
}

.without__template{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 11px;
    border-radius: 8px;
    border: 1px solid #ff7676;
    color: red;
    margin-bottom: 16px;
}
</style>